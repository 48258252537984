import { useEffect, useRef, useState } from 'react';
import { styled } from 'Theme/stitches.config';
import { CSS } from '@stitches/react';
import { Close, Search } from 'DesignSystem/Icons';
import { FocusVisibleResetStyle, FocusVisibleStyle } from 'DesignSystem/Accessibility/Utils';
import { useUiState } from 'Shared/Providers/UiState/UiStateProvider';
import { getSearchQueryParameter } from 'Shared/Common/Helpers';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';


import { useTranslations } from '../../../context/init-data.context';
import { usePageData } from '../../../context/page-data.context';

type PropTypes = {
  doSearch: () => void;
  query: string;
  setQuery: (value: string) => void;
  css?: CSS;
  disabled?: boolean;
  placeholder?: string;
  hasFocus?: boolean;
  onReset?: () => void;
  onFocus?: () => void;
};

function SearchInput({
  doSearch,
  query,
  setQuery,
  css,
  placeholder,
  disabled = false,
  hasFocus = false,
  onReset,
  onFocus,
}: PropTypes) {
  const { pageType } = usePageData<PageModelBase>();
  const isSearchPage = pageType === 'SearchPage';

  const { focusQuickSearch, quickSearchState } = useUiState();
  const [hasValue, setHasValue] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    commonLabels: { search },
    searchLabels: { resetSearch },
  } = useTranslations();

  const clearInput = () => {
    setQuery('');
    setHasValue(false);
    onReset && onReset();
    inputRef && inputRef.current?.focus();
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    doSearch();
  };

  useEffect(() => {
    hasFocus && inputRef && inputRef.current?.focus();

    // UiStateProvider
    if (quickSearchState.isFocused) {
      inputRef.current?.focus();
    }
  }, [hasFocus, quickSearchState.isFocused]);

  useEffect(() => {
    query !== '' ? setHasValue(true) : setHasValue(false);
  }, [query]);

  return (
    <Container
      hasValue={hasValue}
      disabled={disabled}
      css={css}
      onClick={() => {
        if (!disabled) {
          setFocus(true);
          inputRef && inputRef.current?.focus();
          focusQuickSearch(true); // UiStateProvider
        }
      }}
      onSubmit={handleSubmit}
      role="search"
    >
      <InputField
        disabled={disabled}
        showDisabled={disabled}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setHasValue(!!e.currentTarget.value);
          setQuery(e.currentTarget.value);
        }}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.form?.dispatchEvent(
              new Event('submit', { bubbles: true, cancelable: true })
            );
          }
        }}
        value={query}
        type="search"
        placeholder={
          !focus && isSearchPage && getSearchQueryParameter()
            ? getSearchQueryParameter()
            : !focus && !isSearchPage
            ? placeholder
            : ''
        }
        onFocus={onFocus}
        onBlur={() => {
          focusQuickSearch(false); // UiStateProvider
          setFocus(false);
        }}
        aria-label={search}
      />
      {query !== '' && (
        <CloseButton onClick={clearInput} aria-label={resetSearch}>
          <Close size="m" color={'primary'} />
        </CloseButton>
      )}
      <SearchButton onClick={doSearch} type="submit" aria-label={search}>
        <Search color={!disabled ? 'primary' : 'inverse'} size="m" />
      </SearchButton>
    </Container>
  );
}

export default SearchInput;

const Container = styled('form', {
  h: '48px',
  display: 'flex',
  alignItems: 'center',
  py: '$s75',
  px: '$s100',
  w: '51%',
  position: 'relative',
  backgroundColor: '$white',
  border: '$borders$default solid $staticBorderDefault',
  borderRadius: '$radius$rMax',
  color: '$onSurface',
  '&:has(input:focus-visible)': {
    ...FocusVisibleStyle,
    outlineOffset: '-2px',
  },

  variants: {
    hasValue: {
      false: {
        outlineColor: '$quickSearchOutlineNoValue',
      },
    },
    disabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
  },
  '@bpMax720': {
    w: '100%',
  },
});

const SearchButton = styled('button', {
  wh: 48,
  ml: '$s80',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
});

const CloseButton = styled('button', {
  ml: '$s100',
  wh: 48,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  '&:after': {
    content: '',

    position: 'relative',
    display: 'block',
    borderTop: '23px solid $onSearch',
    width: '1px',
    marginLeft: '10px',
  },
});

const InputField = styled('input', {
  h: '100%',
  w: '100%',
  display: 'flex',
  color: '$onSurface',
  flexGrow: 1,
  flexShrink: 1,
  py: '$s75',
  '&:focus': {
    outline: 'none',
  },
  '&::placeholder': {
    color: '$onSurfaceSubtle',
  },
  '&::-webkit-search-cancel-button': {
    WebkitAppearance: 'none',
  },
  fontSize: '$fontSizes$fontSize100',
  lineHeight: '$lh24',
  '&:focus-visible': {
    ...FocusVisibleResetStyle,
  },
  variants: {
    showDisabled: {
      true: {
        cursor: 'not-allowed',
      },
    },
  },
});
